import { cn } from '@hapstack/common'
import type { ButtonStyleProps, IconName } from '@hapstack/ui'
import { buttonStyles, Icon, Spinner } from '@hapstack/ui'
import type { LinkProps } from '@remix-run/react'
import { Link, useNavigation } from '@remix-run/react'

interface LinkButtonProps extends ButtonStyleProps, Omit<LinkProps, 'color'> {
  disabled?: boolean
  icon?: IconName
}

export const LinkButton = ({
  size,
  variant,
  disabled,
  className,
  icon,
  ...props
}: LinkButtonProps) => {
  const navigation = useNavigation()

  const isLoading =
    navigation.state !== 'idle' && navigation.location?.pathname === props.to

  return (
    <Link
      {...props}
      className={cn(
        'flex items-center justify-center gap-2',
        buttonStyles({
          variant,
          size,
        }),
        disabled && 'pointer-events-none opacity-50',
        className
      )}
    >
      {isLoading ? <Spinner /> : icon ? <Icon name={icon} /> : null}
      {props.children}
    </Link>
  )
}
